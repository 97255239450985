<template>
  <b-card id="employee-profile-preview">
    <b-row>
      <b-col>
        <b-form class="bg-white">
          <b-row>
            <b-col
              cols="12"
              class="d-flex justify-content-between align-items-center pb-2"
            >
              <h3>Basic Information</h3>
              <div>
                <router-link
                  v-if="$can('update', 'Employee')"
                  :to="`/employee-profile-update/${employee.id}`"
                  class="text-white"
                >
                  <button class="px-2 btn btn-sm btn-primary edit-btn">
                    <span class="btn-text">Edit</span>
                    <img src="@/assets/images/icons/edit-3.png" alt="" /></button
                ></router-link>
                <router-link
                  v-if="$can('view', 'Employee')"
                  :to="`/employee-profile-print/${employee.id}`"
                  class="text-white ml-1"
                >
                  <button class="px-2 btn btn-sm btn-secondary">
                    <span class="btn-text">Print</span>
                    <img class="print-icon" src="@/assets/images/icons/print.png" alt="" /></button
                ></router-link>
              </div>
              
            </b-col>
            <b-col cols="12" md="2" class="text-center">
              <div class="img-border rounded-circle">
                <img
                  :src="employee.avatar &&
                    employee.avatar.includes('https://ui-avatars.com')
                      ? employee.avatar
                      : $store.state.appConfig.imageBaseUrl + employee.avatar
                  "
                  alt="profile image"
                  class="rounded-circle user-img"
                />
              </div>
            </b-col>
            <b-col>
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group label="First Name" label-for="First Name">
                    <b-form-input
                      id="input-2"
                      v-model="employee.first_name"
                      required
                      class="data-text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="Last Name" label-for="Last Name">
                    <b-form-input
                      id="input-2"
                      v-model="employee.last_name"
                      required
                      class="data-text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="6">
                  <b-form-group
                    label="Contact Number"
                    label-for="Contact Number"
                  >
                    <b-form-input
                      id="input-2"
                      v-model="employee.phone"
                      required
                      class="data-text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="Email Address" label-for="Email Address">
                    <b-form-input
                      id="input-2"
                      v-model="employee.email"
                      required
                      type="email"
                      class="data-text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="logedInUser.roles[0].name !== 'customer'"
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    id="input-group-date-of-birth"
                    label="Date of Birth"
                    label-for="Date of Birth"
                  >
                    <b-form-datepicker
                      id="input-date-of-birth"
                      v-model="employee.employee.birth_date"
                      required
                      class="d-flex align-items-center"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col v-if="employee.register !== 'candidate'" cols="12" md="6">
                  <b-form-group label="Start Date" label-for="Joining Date">
                    <p
                      class="data-text date-text"
                      v-if="employee.employee && employee.employee.start_date"
                    >
                      {{ formateDate(employee.employee.start_date) }}
                    </p>
                    <!-- <b-form-input
                      v-if="employee.employee && employee.employee.start_date"
                      id="input-2"
                      v-model="employee.employee.start_date"
                      required
                      class="data-text"
                      disabled
                    />
                    <b-form-input
                      v-else
                      placeholder="Not Available"
                      class="data-text"
                      disabled
                    /> -->
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group
                    ><label for="gender">Gender </label>
                    <div class="d-flex align-items-center">
                      <b-form-radio
                        v-model="employee.employee.gender"
                        name="gender"
                        class="mr-2"
                        disabled
                        value="M"
                        ><span class="data-text bg-white"
                          >Male</span
                        ></b-form-radio
                      >
                      <b-form-radio
                        v-model="employee.employee.gender"
                        name="gender"
                        class="mr-2"
                        disabled
                        value="F"
                        ><span class="data-text bg-white"
                          >Female</span
                        ></b-form-radio
                      >
                    </div>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6" v-if="userType !== 'customer'">
                  <b-form-group
                    label=" Citizen Type"
                    label-for=" Citizen Type"
                  >
                    <div class="d-flex align-items-center">
                      <b-form-radio
                        v-model="employee.employee.resident_type"
                        name="some-radios"
                        class="mr-2"
                        value="1"
                        disabled
                      >
                        <span class="data-text bg-white">AU/NZ Citizen</span>
                      </b-form-radio>
                      <b-form-radio
                        v-model="employee.employee.resident_type"
                        name="some-radios"
                        class="mr-2"
                        value="0"
                        disabled
                        ><span class="data-text bg-white"
                          >Non AU/NZ Citizen</span
                        ></b-form-radio
                      >
                    </div>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6" v-if="userType !== 'customer'">
                  <b-form-group
                    label="Employment Type"
                    label-for="Employment Type"
                  >
                    <b-form-input
                      id="input-2"
                      v-model="employeement_type"
                      required
                      class="data-text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6" v-if="userType !== 'customer'">
                  <b-form-group label="Job Role" label-for="Job Role">
                    <b-form-input
                      id="input-2"
                      v-model="FunctionName"
                      required
                      class="data-text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6" v-if="userType !== 'customer' && employee.register !== 'candidate'">
                  <b-form-group label="Position" label-for="Position">
                    <b-form-input
                      id="input-2"
                      v-model="PositionName"
                      required
                      class="data-text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="
                    logedInUser.roles[0].name === 'superadmin' ||
                    logedInUser.roles[0].name === 'admin'
                  "
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Payment Per Hour"
                    label-for="payment-per-hour"
                  >
                    <b-form-input
                      id="input-2"
                      v-model="employee.employee.payment_per_hour"
                      required
                      class="data-text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6" v-if="userType !== 'customer'">
                  <b-form-group label="Nationality" label-for="Nationality">
                    <b-form-input
                      id="input-2"
                      v-model="nationality"
                      required
                      class="data-text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6" v-if="userType !== 'customer' || employee.register === 'candidate'">
                  <b-form-group v-if="userType !== 'employee'" label="Note" label-for="Note">
                    <b-form-textarea
                      id="note"
                      v-model="employee.employee.note"
                      placeholder="Enter Note"
                      rows="3"
                      max-rows="6"
                      class="data-text bg-light"
                      disabled
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>

              <fieldset class="border px-2" v-if="userType !== 'customer'">
                <legend class="float-none w-auto px-1">
                  Residential Address
                </legend>
                <b-row>
                  <b-col cols="12" md="6">
                    <b-form-group
                      id="input-group-2"
                      label="Address"
                      label-for="Address"
                    >
                      <b-form-input
                        id="input-2"
                        v-model="employee.employee.address"
                        required
                        class="data-text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group
                      id="Zip-Code-2"
                      label="Postal Code"
                      label-for="Zip-Code"
                    >
                      <b-form-input
                        id="Zip-Code"
                        v-model="employee.employee.zip_code"
                        required
                        class="data-text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group
                      id="input-group-2"
                      label="Suburb"
                      label-for="Suburb"
                    >
                      <b-form-input
                        id="input-2"
                        v-model="employee.employee.city"
                        required
                        class="data-text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="6">
                    <b-form-group
                      id="input-group-2"
                      label="State"
                      label-for="State"
                    >
                      <b-form-input
                        id="input-2"
                        v-model="employee.employee.region"
                        required
                        class="data-text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </fieldset>
              <fieldset
                class="border px-2"
                v-if="
                  employee.employee.is_postal_same_as_residential === 0 &&
                  userType !== 'customer'
                "
              >
                <legend class="float-none w-auto px-1">Postal Address</legend>
                <b-row>
                  <b-col cols="12">
                    <b-form-group>
                      <b-form-textarea
                        id="textarea"
                        v-model="employee.employee.postal_address"
                        rows="3"
                        max-rows="6"
                        class="data-text"
                        disabled
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>
              </fieldset>
              <hr />
            </b-col>
          </b-row>

          <b-row v-if="userType !== 'customer'">
            <b-col cols="12" md="2" class="text-center">
              <h3>Next To Kin</h3>
            </b-col>
            <b-col>
              <b-row>
                <b-col cols="12" md="12">
                  <b-form-group label=" Name" label-for=" Name">
                    <b-form-input
                      id="input-2"
                      v-model="employee.employee.keen.name"
                      required
                      class="data-text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group
                    label="Relation With Employee"
                    label-for="Relation With Employee"
                  >
                    <b-form-input
                      id="input-2"
                      v-model="employee.employee.keen.relation"
                      required
                      class="data-text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="Address" label-for="Living Address">
                    <b-form-input
                      id="input-2"
                      v-model="employee.employee.keen.address"
                      required
                      class="data-text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group
                    label="Contact Number"
                    label-for="Contact Number"
                  >
                    <b-form-input
                      id="input-2"
                      v-model="employee.employee.keen.phone"
                      required
                      class="data-text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="Email Address" label-for="Email Address">
                    <b-form-input
                      id="input-2"
                      v-model="employee.employee.keen.email"
                      type="email"
                      required
                      class="data-text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <hr />
            </b-col>
          </b-row>

          <b-row v-if="userType !== 'customer'">
            <b-col cols="12" md="2" class="text-center">
              <h3>
                <img
                  v-if="employee.employee.passport.status == 1"
                  src="@/assets/images/icons/tick_badge.png"
                  alt="aproved"
                />
                Passport
              </h3>
            </b-col>

            <b-col>
              <b-row>
                <b-col>
                  <b-form-group
                    label=" Issued Country"
                    label-for=" Issued Country"
                  >
                    <b-form-input
                      id="input-2"
                      v-model="passport_issue_country"
                      required
                      class="data-text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group
                    label="Passport Number"
                    label-for="Passport Number"
                  >
                    <b-form-input
                      id="input-2"
                      v-model="employee.employee.passport.passport_number"
                      required
                      class="data-text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group
                    id="input-group-2-pass-validation"
                    label="Validation"
                    label-for="Validation"
                  >
                    <b-form-datepicker
                      id="input-pass-validation"
                      v-model="employee.employee.passport.passport_validation"
                      required
                      class="data-text"
                      disabled
                    />
                  </b-form-group>
                  <p
                    v-if="
                      employee.employee.passport &&
                      employee.employee.passport.passport_validation
                    "
                    :class="
                      getValidationClass(
                        employee.employee.passport.passport_validation
                      )
                    "
                  >
                    Your passport will be expired by
                    <span class="date-text">{{
                      formateDate(
                        employee.employee.passport.passport_validation
                      )
                    }}</span>
                  </p>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="View Passport" label-for="View Passport">
                    <div
                      class="d-flex justify-content-between align-items-center passport-padding rounded"
                    >
                      <p class="px-1 m-0">
                        {{ employee.employee.passport.passport_file }}
                      </p>
                      <p :class="`${!employee.employee.passport.passport_file ? 'initialPadding': ''} m-0`">
                        <img
                          v-if="employee.employee.passport.passport_file"
                          v-b-modal.modal-center
                          @click.prevent="
                            getPreviewFile(
                              employee.employee.passport.passport_file
                            )
                          "
                          src="@/assets/images/icons/eye.png"
                          alt=""
                        />
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr />
            </b-col>
          </b-row>
          <b-row v-if="userType !== 'customer' && employee.employee.visa.resident_type == 0">
            <b-col cols="12" md="2" class="text-center">
              <h3>Visa</h3>
            </b-col>
            <b-col>
              <b-row>
                <!-- <b-col cols="12" md="6">
                  <b-form-group
                    id="input-group-2-pass-validation"
                    label="Citizen Type"
                    label-for="Citizen Type"
                  >
                    <p
                      class="data-text custom-text"
                      v-if="employee.employee.visa.resident_type == 1"
                    >
                      AU/NZ Citizen
                    </p>
                    <p
                      class="data-text custom-text"
                      v-else-if="employee.employee.visa.resident_type == 0"
                    >
                      Non-AU/NZ Citizen
                    </p>
                    <p class="data-text custom-text" v-else>
                      No Visa Info Found
                    </p>
                  </b-form-group>
                </b-col> -->
                <b-col
                  cols="12"
                  md="6"
                  v-if="employee.employee.visa.resident_type == 0"
                >
                  <b-form-group label="Visa Type" label-for="Visa Type">
                    <b-form-input
                      v-if="SelectedVisa && SelectedVisa.name"
                      id="input-2"
                      v-model="SelectedVisa.name"
                      required
                      class="data-text"
                      disabled
                    />
                    <p v-else class="data-text custom-text">N/A</p>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  v-if="employee.employee.visa.resident_type == 0"
                >
                  <b-form-group label="Sub Visa Type" label-for="subvisatype">
                    <b-form-input
                      v-if="selectedSubClass && selectedSubClass.name"
                      id="subvisatype"
                      v-model="selectedSubClass.name"
                      required
                      class="data-text"
                      disabled
                    />
                    <p v-else class="data-text custom-text">N/A</p>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                  v-if="employee.employee.visa.resident_type == 0"
                >
                  <b-form-group
                    id="input-group-2-pass-validation"
                    label="Expiary Date"
                    label-for="Expiary date"
                  >
                    <b-form-datepicker
                      id="input-pass-validation"
                      v-model="employee.employee.visa.expiry_date"
                      required
                      class="data-text"
                      disabled
                    />
                  </b-form-group>
                  <p
                    v-if="
                      employee.employee.visa &&
                      employee.employee.visa.expiry_date &&
                      employee.employee.visa.resident_type == 0
                    "
                    :class="
                      getValidationClass(employee.employee.visa.expiry_date)
                    "
                  >
                    Your Visa will be expired by
                    <span class="date-text">{{
                      employee.employee.visa
                        ? formateDate(employee.employee.visa.expiry_date)
                        : "N/A"
                    }}</span>
                  </p>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                  v-if="employee.employee.visa.resident_type == 0"
                >
                  <b-form-group
                    id="input-group-2-pass-validation"
                    label="Visa Grant Number"
                    label-for="visa Grant Number"
                  >
                    <b-form-input
                      id="input-pass-validation"
                      v-model="employee.employee.visa.visa_grant_number"
                      required
                      class="data-text"
                      disabled
                    />
                  </b-form-group>
                  <span class="date-text">
                    <a
                      href="https://online.immi.gov.au/evo/firstParty?actionType=query"
                      target="_blank"
                      >Check</a
                    >
                  </span>
                </b-col>
              </b-row>
              <hr />
            </b-col>
          </b-row>
          <b-row v-if="userType !== 'customer'">
            <b-col cols="12" md="2" class="text-center">
              <h3>Tax Information</h3>
            </b-col>
            <b-col>
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                  v-if="
                    employee.employee.tax && employee.employee.tax.tfn_number
                  "
                >
                  <b-form-group label="TFN Number" label-for="TFN Number">
                    <b-form-input
                      v-if="employee.employee.tax"
                      id="TFN Number"
                      v-model="employee.employee.tax.tfn_number"
                      required
                      class="data-text"
                      disabled
                    />
                    <p v-else class="data-text custom-text">N/A</p>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                  v-if="
                    employee.employee.tax && employee.employee.tax.tfn_exemption
                  "
                >
                  <b-form-group label="TFN Exemption" label-for="TFN Exemption">
                    <b-form-input
                      v-if="employee.employee.tax"
                      id="TFN Exemption"
                      v-model="employee.employee.tax.tfn_exemption"
                      required
                      class="data-text"
                      disabled
                    />
                    <p v-else class="data-text custom-text">N/A</p>
                  </b-form-group>
                </b-col>
                <!-- <b-col cols="12" md="6">
                  <b-form-group
                    id="input-group-2-pass-validation"
                    label="Citizen Type"
                    label-for="Citizen Type"
                  >
                    <p
                      class="data-text custom-text"
                      v-if="employee.employee.visa.resident_type == 1"
                    >
                      AU/NZ Citizen
                    </p>
                    <p
                      class="data-text custom-text"
                      v-else-if="employee.employee.visa.resident_type == 0"
                    >
                      Non-AU/NZ Citizen
                    </p>
                    <p class="data-text custom-text" v-else>
                      No Visa Info Found
                    </p>
                  </b-form-group>
                </b-col> -->
                <b-col cols="12" md="6">
                  <b-form-group
                    ><label for=""
                      >Tax Free Thrashold
                      <!-- {{ employee.employee.tax.free_tax_threshold }} -->
                    </label>
                    <b-form-checkbox
                      id="claim"
                      v-if="
                        employee.employee.tax &&
                        employee.employee.tax.free_tax_threshold
                      "
                      v-model="employee.employee.tax.free_tax_threshold"
                      name="checkbox-1"
                      value="1"
                      unchecked-value="0"
                      disabled
                    >
                      Claimed Tax Free Thrashold
                    </b-form-checkbox>
                    <b-form-checkbox
                      id="claim"
                      v-else
                      name="checkbox-1"
                      value="1"
                      unchecked-value="0"
                      disabled
                    >
                      Not Claimed
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="6">
                  <b-form-group
                    label="Tax Scale Type"
                    label-for="Tax Scale Type"
                  >
                    <b-form-input
                      v-if="employee.employee.tax"
                      id="TFN Number"
                      v-model="employee.employee.tax.tax_scale_type"
                      required
                      class="data-text"
                      disabled
                    />
                    <p v-else class="data-text custom-text">N/A</p>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr />
            </b-col>
          </b-row>
          <b-row v-if="userType !== 'customer'">
            <b-col cols="12" md="2" class="text-center">
              <h3 class="px-1 mb-0">
                <img
                  v-if="employee.employee.driving.status == 1"
                  src="@/assets/images/icons/tick_badge.png"
                  alt="aproved"
                />
                Driving License
              </h3>
            </b-col>
            <b-col>
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group label=" Issued State" label-for=" Issued State">
                    <b-form-input
                      id="input-2"
                      v-model="employee.employee.driving.issuing_state"
                      required
                      class="data-text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group
                    label="License Number"
                    label-for="License Number"
                  >
                    <b-form-input
                      id="input-2"
                      v-model="employee.employee.driving.license_number"
                      required
                      class="data-text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group
                    id="input-group-2-dl-validation"
                    label="Validation"
                    label-for="Validation"
                  >
                    <b-form-datepicker
                      id="input-dl-validation"
                      v-model="employee.employee.driving.license_validation"
                      required
                      class="data-text"
                      disabled
                    />
                  </b-form-group>
                  <p
                    v-if="
                      employee.employee.driving &&
                      employee.employee.driving.license_validation
                    "
                    :class="
                      getValidationClass(
                        employee.employee.driving.license_validation
                      )
                    "
                  >
                    Your driving license will be expired by
                    <span class="date-text">{{
                      formateDate(employee.employee.driving.license_validation)
                    }}</span>
                  </p>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="View License" label-for="View License">
                    <div
                      class="d-flex justify-content-between align-items-center passport-padding rounded"
                    >
                      <p class="px-1 m-0">
                        {{ employee.employee.driving.license_file }}
                      </p>
                      <p :class="`${!employee.employee.driving.license_file ? 'initialPadding': ''} m-0`">
                        <img
                          v-if="employee.employee.driving.license_file"
                          v-b-modal.modal-center
                          @click.prevent="
                            getPreviewFile(employee.employee.driving.license_file)
                          "
                          src="@/assets/images/icons/eye.png"
                          alt=""
                        />
                      </p>
                      
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr />
            </b-col>
          </b-row>
          <b-row v-if="userType !== 'customer'">
            <b-col cols="12" md="2" class="text-center">
              <h3 class="px-1 mb-0">Certificate Details</h3>
            </b-col>
            <b-col>
              <div
                v-for="(certificate, index) in employee.employee.certificate"
                class="card" :key="index"
              >
                <b-row>
                  <!-- {{ certificate.status }} -->
                  <b-col cols="12" md="6">
                    <b-form-group>
                      <label for="Name of certification">
                        <img
                          v-if="(certificate.status = 1)"
                          src="@/assets/images/icons/blue-badge.png"
                          height="20"
                          width="20"
                          alt="badge"
                          class="mr-1"
                        />Name of certification</label
                      >
                      <b-form-input
                        id="input-2"
                        v-model="certificate.certificate_name"
                        required
                        class="data-text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group
                      label="Issued Organization"
                      label-for="Issued Organization"
                    >
                      <b-form-input
                        id="input-2"
                        v-model="certificate.certificate_issuing_authority"
                        required
                        class="data-text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" md="6">
                    <b-form-group
                      label="Certification Number"
                      label-for="Name of Certification Number"
                    >
                      <b-form-input
                        id="input-2"
                        v-model="certificate.certificate_number"
                        required
                        class="data-text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="6">
                    <b-form-group
                      id="input-group-2-isued-date"
                      label="Issued Date"
                      label-for="Issued Date"
                    >
                      <b-form-datepicker
                        id="input-isued-date"
                        v-model="certificate.certificate_issuing_date"
                        required
                        class="data-text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group
                      label="Certification Expiry Date"
                      label-for="Certification Validation"
                    >
                      <b-form-datepicker
                        id="input-2"
                        v-model="certificate.certificate_validation"
                        required
                        class="data-text"
                        disabled
                      />
                    </b-form-group>
                    <p
                      v-if="certificate && certificate.certificate_validation"
                      :class="
                        getValidationClass(
                          employee.employee.driving.license_validation
                        )
                      "
                    >
                      Your {{ certificate.certificate_name }} certification will
                      be expired by
                      <span class="date-text">{{
                        formateDate(certificate.certificate_validation)
                      }}</span>
                    </p>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group
                      label="View Certification"
                      label-for="View Certification"
                    >
                      <div
                        class="d-flex justify-content-between align-items-center passport-padding rounded"
                      >
                        <p class="m-0">
                          {{ certificate.certificate_file }}
                        </p>
                        <p :class="`${!certificate.certificate_file ? 'initialPadding': ''} m-0`">
                          <img
                            v-if="certificate.certificate_file"
                            v-b-modal.modal-center
                            @click.prevent="
                              getPreviewFile(certificate.certificate_file)
                            "
                            src="@/assets/images/icons/eye.png"
                            alt=""
                          />
                        </p>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
              <hr />
            </b-col>
          </b-row>
          <b-row v-if="userType !== 'customer' && items.length > 0">
            <b-col cols="12" md="2" class="text-center">
              <div
                class="d-flex flex-row justify-content-center align-items-center"
              >
                <!-- <img src="@/assets/images/icons/blue-badge.png" alt="" width="25" height="25" /> -->
                <h3 class="px-1 mb-0">Skill Set</h3>
              </div>
            </b-col>
            <b-col>
              <div v-if="items.length > 0">
                <b-row v-for="(item, index) in items" :key="index">
                  <b-col cols="12" md="6">
                    <b-form-group label=" Skill Name" label-for=" Skill Name">
                      <p class="data-text skill">
                        {{ getSkillName(item.skill_id) }}
                      </p>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group label="Skill Level" label-for="Result">
                      <p class="data-text skill">{{ getExamResult(item) }}</p>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
              <div v-else>
                <b-row>
                  <b-col cols="12" md="6">
                    <b-form-group label=" Skill Name" label-for=" Skill Name">
                      <b-form-input
                        id="input-2"
                        v-model="demo"
                        required
                        class="data-text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group label="Result" label-for="Result">
                      <b-form-input
                        id="input-2"
                        v-model="demo"
                        required
                        class="data-text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
              <hr />
            </b-col>
          </b-row>
          <b-row v-if="userType !== 'customer'">
            <b-col cols="12" md="2" class="text-center">
              <h3>Bank Details</h3>
            </b-col>
            <b-col>
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group label="Bank Name" label-for=" Bank Name">
                    <b-form-input
                      id="input-2"
                      v-model="employee.employee.bank_info.bank_name"
                      required
                      class="data-text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="Account Name" label-for="Branch">
                    <b-form-input
                      id="input-2"
                      v-model="employee.employee.bank_info.address"
                      required
                      class="data-text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group label="BSB Number" label-for="BSB number">
                    <b-form-input
                      id="input-2"
                      v-model="employee.employee.bank_info.personal_bsb_number"
                      required
                      class="data-text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group
                    label="Account Number"
                    label-for="Account Number"
                  >
                    <b-form-input
                      id="input-2"
                      v-model="
                        employee.employee.bank_info.personal_account_number
                      "
                      required
                      class="data-text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <hr />
            </b-col>
          </b-row>
          <b-row v-if="userType !== 'customer'">
            <b-col cols="12" md="2" class="text-center">
              <h3>Super</h3>
            </b-col>
            <b-col>
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group label="Fund Name" label-for=" Fund Name">
                    <b-form-input
                      id="input-2"
                      v-model="employee.employee.bank_info.super_name"
                      required
                      class="data-text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="USI " label-for="USI">
                    <b-form-input
                      id="input-2"
                      v-model="employee.employee.bank_info.super_phone_number"
                      required
                      class="data-text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group label=" Account Name" label-for=" Account name">
                    <b-form-input
                      id="input-2"
                      v-model="employee.employee.bank_info.super_abn"
                      required
                      class="data-text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="Member Number" label-for="Member number">
                    <b-form-input
                      id="input-2"
                      v-model="employee.employee.bank_info.account_type"
                      required
                      class="data-text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group label=" BSB Number" label-for=" BSB Number">
                    <b-form-input
                      id="input-2"
                      v-model="employee.employee.bank_info.super_bsb_number"
                      required
                      class="data-text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label=" ABN Number" label-for=" ABN Number">
                    <b-form-input
                      id="input-2"
                      v-model="employee.employee.bank_info.super_account_number"
                      required
                      class="data-text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="userType !== 'customer'">
            <b-col cols="12" md="2" class="text-center mt-2">
              <h3>Availability</h3>
            </b-col>
            <b-col cols="12" md="10" class="text-center">
              <hr />
              <b-table
                class="data-text-custom"
                striped
                hover
                :items="availability"
                :fields="fields"
              >
                <template #cell(sl)="data">
                  {{ data.index + 1 }}
                </template>
                <template #cell(day)="data">
                  {{ data.item.day ? data.item.day.name : "" }}
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row
            v-if="
              (userType === 'superadmin' || userType === 'employee') &&
              companies.length > 0
            "
            class="mt-2"
          >
            <b-col cols="12" md="2" class="text-center mt-2">
              <h3>Companies</h3>
            </b-col>
            <b-col cols="12" md="10" class="text-center">
              <hr />
              <b-table
                class="data-text-custom"
                striped
                hover
                :items="companies"
                :fields="companyFields"
              >
                <template #cell(sl)="data">
                  {{ data.index + 1 }}
                </template>
                <template #cell(address)="data">
                  {{ data.item.info.address }}
                </template>
                <template #cell(location)="data">
                  {{ data.item.info.city }} , {{ data.item.info.state }}
                </template>
                <template #cell(action)="data">
                  <b-link
                    v-if="$can('view', 'Company')"
                    :to="`/company/view/${data.item.id}`"
                    class="btn btn-info btn-sm"
                  >
                    View
                  </b-link>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row
            v-if="userType !== 'customer' && clients.length > 0"
            class="mt-2"
          >
            <b-col cols="12" md="2" class="text-center mt-2">
              <h3>Assigned To</h3>
            </b-col>
            <b-col cols="12" md="10" class="text-center">
              <hr />
              <b-table
                class="data-text-custom"
                striped
                hover
                :items="clients"
                :fields="companyFields"
              >
                <template #cell(sl)="data">
                  {{ data.index + 1 }}
                </template>
                <template #cell(address)="data">
                  {{ data.item.info.address }}
                </template>
                <template #cell(location)="data">
                  {{ data.item.info.city }} , {{ data.item.info.state }}
                </template>
                <template #cell(action)="data">
                  <b-link
                    v-if="$can('view', 'Client')"
                    :to="`/customer/view/${data.item.id}`"
                    class="btn btn-info btn-sm"
                  >
                    View
                  </b-link>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row v-if="rosterWorks.length > 0 && $can('list', 'Timesheet')" class="mt-2 d-flex">
            <b-col
              v-if="userType !== 'customer'"
              cols="12"
              md="2"
              class="text-center mt-2"
            >
              <h3>Timesheets</h3>
            </b-col>
            <b-col cols="12" md="10" class="text-center">
              <hr />
              <div class="row d-flex align-items-center">
                <div class="col-md-6"></div>
                <div class="col-md-6">
                  <div class="row text-left">
                    <div class="col-8">
                      <label for="" class="label-font">Filter By Date</label>
                      <b-form-group class="my-date-range">
                        <flat-pickr
                          v-model="rangeDate"
                          class="form-control bg-light border-dark-1"
                          :config="{ mode: 'range', altInput: true }"
                          placeholder="Select Date Range & Filter"
                          v-debounce:1000="getRosterWorkList"
                        />
                        <button
                          v-if="rangeDate"
                          class="btn btn-sm btn-danger text-end d-block ml-1"
                          type="button"
                          title="Clear"
                          data-clear
                          @click="clearDate"
                        >
                          <span>X</span>
                        </button>
                      </b-form-group>
                    </div>
                    <div class="col-4">
                      <b-form-group>
                        <h5>Per Page</h5>
                        <v-select
                          v-model="timeQuery.per_page"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="per_pages"
                          placeholder="Filter By Per Page"
                          :clearable="false"
                          @input="getRosterWorkList"
                        />
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <time-sheet-table-component
                :roster-works="rosterWorks"
                :fields="timesheetFields"
                :total="totalTimesheet"
                :form="fromTimesheet"
              />
              <b-pagination
                class="mt-3 p-1 d-flex justify-content-end bg-white"
                v-if="totalTimesheet > timeQuery.per_page"
                v-model="timeQuery.page"
                :total-rows="totalTimesheet"
                :per-page="timeQuery.per_page"
                aria-controls="my-table"
                @change="getRosterWorkList($event)"
                pills
                size="lg"
                page-class="info"
              ></b-pagination>
            </b-col>
          </b-row>
          <b-row v-if="userType !== 'customer' && payslips.length > 0">
            <b-col cols="12" md="2" class="text-center mt-2">
              <h3>Payslip History</h3>
            </b-col>
            <b-col cols="12" md="10" class="text-center">
              <hr />
              <b-table
                class="data-text-custom"
                striped
                hover
                :items="payslips"
                :fields="payslipFields"
              >
                <template #cell(sl)="data">
                  {{ data.index + formPayslip }}
                </template>
                <template #cell(period)="data">
                  Week Ending
                  {{ formateDate(data.item.pay_run.PayRunPeriodEndDate) }}
                </template>
                <template #cell(payment_date)="data">
                  {{ formateDate(data.item.pay_run.PaymentDate) }}
                </template>
                <template #cell(total__net_pay)="data">
                  {{ data.item.net_pay }}
                </template>
              </b-table>
              <b-pagination
                class="mt-3 p-1 d-flex justify-content-end bg-white"
                v-if="totalPayslip > payQuery.per_page"
                v-model="payQuery.page"
                :total-rows="totalPayslip"
                :per-page="payQuery.per_page"
                aria-controls="my-table"
                @change="getEmployeePaySlip($event)"
                pills
                size="lg"
                page-class="info"
              ></b-pagination>
            </b-col>
          </b-row>
          <b-row v-if="userType !== 'customer'" class="mt-2">
            <b-col v-if="$can('view published roster', 'Roster')" cols="12" class="text-center">
              <hr />
              <employee-roster />
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
    <b-modal hide-footer id="modal-center" size="lg" centered>
      <div class="text-center p-2" id="printMe">
        <img id="print-me" v-if="previewFile && previewFile.includes('.png','.jpg','.jpeg')" :src="previewFile" height="600px" width="100%" alt="file" />
        <iframe v-if="previewFile && previewFile.includes('.pdf')" :src="previewFile" height="600px" width="100%" alt="file" />
      </div>

      <template>
        <!-- Button with custom close trigger value -->
        <div class="download-btn">
          <b-button size="md" variant="secondary" @click="downloadDocument(previewFile)">
          Download
        </b-button>
        <b-button v-if="previewFile && previewFile.includes('.png','.jpg','.jpeg')" class="ml-2"  size="md" variant="warning" @click="PrintMe">
          Print
        </b-button>
        </div>
      </template>
    </b-modal>
  </b-card>
</template>

<script>
import TimeSheetTableComponent from "@/components/timesheet/TimeSheetTableComponent.vue";
import { getLogedInUser } from "@/utils/auth";
import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";
import printHtmlBlock from 'print-html-block';
import {
  BAlert,
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormSelect,
  BFormTextarea,
  BLink,
  BModal,
  BPagination,
  BRow,
  BTable,
} from "bootstrap-vue";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import EmployeeRoster from "./employee-roster.vue";
export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckboxGroup,
    BFormDatepicker,
    BFormCheckbox,
    BButton,
    BCard,
    BFormTextarea,
    BFormDatepicker,
    BModal,
    BAlert,
    BTable,
    BFormRadio,
    BPagination,
    EmployeeRoster,
    BLink,
    flatPickr,
    vSelect,
    TimeSheetTableComponent,
  },
  directives: {},
  props: {
    employee: {
      type: Object,
      default: () => ({}),
    },
    availability: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      query: {
        page: 1,
        per_page: 20,
      },
      timeQuery: {
        page: 1,
        per_page: 20,
      },
      payQuery: {
        page: 1,
        per_page: 20,
      },
      per_pages: [20, 50, 100, 200, 500],
      total: 0,
      from: 0,
      to: 0,
      totalTimesheet: 0,
      toTimesheet: 0,
      fromTimesheet: 0,
      form: {
        email: "",
        jobTitle: "",
        catagory: "",
        vacancy: "",
        location: "",
        food: null,
        checked: [],
      },
      nationality: "",
      PositionName: "",
      FunctionName: "",
      userType: "",
      expiredDuration: 0,
      demo: "",
      show: true,
      previewFile: null,
      items: [],
      options: [],
      clients: [],
      companies: [],
      rosterWorks: [],
      payslips: [],
      totalPayslip: 0,
      formPayslip: 0,
      rangeDate: null,
      fields: ["sl", "day", "start_time", "end_time"],
      payslipFields: [
        "sl",
        "period",
        "payment_date",
        this.employee?.employee?.employment_type_id != 3
          ? "leave_balance"
          : "",
        "super",
        "total__net_pay",
      ],
      companyFields: [
        "sl",
        "id",
        "name",
        "address",
        "location",
        "phone",
        "email",
        "action",
      ],
      timesheetFields: [
        "SL",
        "date",
        JSON.parse(getLogedInUser()).roles[0].name === "admin"
          ? ""
          : JSON.parse(getLogedInUser()).roles[0].name !== "employee"
          ? "company"
          : "assignedBy",

        JSON.parse(getLogedInUser()).roles[0].name === "customer"
          ? ""
          : JSON.parse(getLogedInUser()).roles[0].name !== "employee"
          ? "client"
          : "assignedTo",
        JSON.parse(getLogedInUser()).roles[0].name !== "employee"
          ? "employee"
          : "",
        "in",
        "out",
        "shift_hours",
        // "m",
        // "e",
        // "n",
        "ot_1",
        "ot_2",
        "total_hours",
        "status",
      ],
      SelectedCountry: null,
      SelectedVisa: null,
      SelectedVisaSubClass: null,
      visa: [],
      visaSubClass: [],
      selectedSubClass: {},
      passport_issue_country: null,
      employeement_type: null,
      logedInUser: JSON.parse(getLogedInUser()),
      resident_types: [
        {
          id: 1,
          name: "AU/NZ Citizen",
        },
        {
          id: 0,
          name: "Non-AU/NZ Citizen",
        },
      ],
    };
  },

  async created() {
    this.userType = this.logedInUser.roles[0].name;
    if (this.userType !== "customer") {
      await this.getRosterWorkList();
      this.getVisa();
      this.getSkills();
      this.getSkillTestResult();
      if(this.employee.register !== 'candidate'){
        await this.getEmployeePaySlip();
      }
    }
  },
  mounted() {
    if (this.userType !== "customer") {
      setTimeout(async () => {
        if (this.employee.employee.visa.visa_type !== null) {
          this.SelectedVisa = this.visa.find(
            (visa) => visa.id == this.employee.employee.visa.visa_type
          );
          if (this.SelectedVisa !== undefined) {
            this.getSelectedVisaInfo();
          }
        }
        await this.getCountry(
          this.employee.employee.passport.passport_issue_country
        );
        await this.getNationality(this.employee.employee.country_id);
        this.getEmploymentTypes();
        if (this.employee.employee.position_id !== null) {
          this.getPositionName(this.employee.employee.position_id);
        }
        if (this.employee.employee.sector_id !== null) {
          this.getFunctionName(this.employee.employee.sector_id);
        }
        if (this.employee && this.employee.id && this.employee.register !== 'candidate') {
          this.getEmployeeAssignedToAssignedByList(this.employee.id);
        }
      }, 2000);
    }
  },
  methods: {
    async getPositionName(id) {
      if (id) {
        let response = await this.$store.dispatch("position/getPosition", id);
        this.PositionName = response.data?.name;
      }
    },
    formateDate(date) {
      // format date to dd-mm-YYYY
      return moment(date).format("DD-MM-YYYY");
    },
    async getFunctionName(id) {
      if (id) {
        let response = await this.$store.dispatch("duty/getDuty", id);
        this.FunctionName = response.data?.name;
      }
    },
    getValidationClass(date) {
      // date is greater 3 months from today show success
      if (moment(date).isAfter(moment().add(3, "months"))) {
        return "text-success";
      } else if (moment(date).isBefore(moment().add(3, "months"))) {
        return "text-danger";
      } else {
        return "text-danger";
      }
    },
    async getCountry(id) {
      if (id) {
        let response = await this.$store.dispatch("country/getCountry", id);
        this.passport_issue_country = response.data.name;
      }
    },
    async getNationality(id) {
      if (id) {
        let response = await this.$store.dispatch("country/getCountry", id);
        this.nationality = response.data.name;
      }
    },
    getEmploymentTypes() {
      this.$store
        .dispatch("employmentType/getEmploymentTypes", this.query)
        .then((response) => {
          this.employeement_type = response.data.data.find(
            (x) => x.id == this.employee.employee?.employment_type_id
          )?.name;
        });
    },
    getSkillName(id) {
      if (this.options.length > 0) {
        let skill = this.options.find((x) => x.id == id);
        return skill.skill;
      }
    },
    getSkills() {
      this.$store
        .dispatch("employee/getSkills")
        .then((res) => {
          if (res.status_code === 200) {
            this.options = res.data;
          } else {
            this.errorMsg = "couldn't inserted";
          }
        })
        .catch((err) => {
          this.errorMsg = "please check code";
        });
    },
    getSkillTestResult() {
      this.$store
        .dispatch("employee/getSkillTestResult", this.$route.params.id)
        .then((res) => {
          if (res.status == 200) {
            this.items = res.data.data;
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPreviewFile(file) {
      this.previewFile = this.$store.state.appConfig.imageBaseUrl + file;
    },
    getDate() {
      let currentDate = new Date();
      let differ =
        currentDate - this.employee.employee.driving.license_validation;
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const firstDate = new Date(2008, 1, 12);
      const secondDate = this.employee.employee.driving.license_validation;

      const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
    },
    getExamResult(item) {
      // if marks is greater than 60% then pass else fail
      let result = item.marks / item.questions_count;
      if (result > 0.6 && result < 0.7) {
        return "Beginner";
      } else if (result > 0.7 && result < 0.8) {
        return "intermediate";
      } else if (result > 0.8) {
        return "Expert";
      } else {
        return "Failed";
      }
    },
    getVisa() {
      this.$store
        .dispatch("employee/getVisa")
        .then((res) => {
          this.visa = res.data;
          if (this.employee.employee.visa.visa_type !== null) {
            this.SelectedVisa = this.visa.find(
              (visa) => visa.id == this.employee.employee.visa.visa_type
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getSelectedVisaInfo() {
      await this.$store
        .dispatch("employee/getVisaSubClass", this.SelectedVisa.id)
        .then((res) => {
          this.visaSubClass = res.data;
          this.selectedSubClass = this.visaSubClass.find(
            (visa) => visa.id == this.employee.employee.visa.sub_class
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getEmployeeAssignedToAssignedByList(employee_id) {
      await this.$store
        .dispatch("employee/getEmployeeAssignedToAssignedByList", employee_id)
        .then((res) => {
          let data = res.data.data;
          if (data.employee_customer.length > 0) {
            this.clients = data.employee_customer;
          }
          if (data.employee_to_company.length > 0) {
            this.companies = data.employee_to_company;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clearDate() {
      this.rangeDate = null;
      this.timeQuery.date = "";
      this.timeQuery.end = "";
      this.getRosterWorkList();
    },
    async getRosterWorkList(e) {
      if (e && typeof e === "number") {
        if (this.timeQuery.page !== this.timeQuery.per_page) {
          this.timeQuery.page = 1;
        } else {
          this.timeQuery.page = e;
        }
      } else {
        this.timeQuery.page = 1;
      }
      if (this.rangeDate && this.rangeDate.length > 20) {
        // split the string in arrar
        let arr = this.rangeDate.split(" to ");
        this.timeQuery.date = arr[0];
        this.timeQuery.end = arr[1];
      }
      this.timeQuery.employee_id = this.$route.params.id;
      if (this.logedInUser.roles[0].name === "admin") {
        let roles = this.logedInUser.roles.map((role) => {
          return role.name;
        });
        if (roles.includes("supervisor")) {
          this.timeQuery.user_id = this.logedInUser.supervisor.company_id;
        } else {
          this.timeQuery.user_id = this.logedInUser.id;
        }
      }

      await this.$store
        .dispatch("roster/getRosterbyId", this.timeQuery)
        .then((response) => {
          if (response.status_code == 200) {
            this.rosterWorks = response.data.data;
            this.totalTimesheet = response.data.total;
            this.fromTimesheet = response.data.from;
            this.toTimesheet = response.data.to;
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    async getEmployeePaySlip(e) {
      if (this.logedInUser.roles[0].name === "employee") {
        this.payQuery.id = this.logedInUser.id;
      } else {
        this.payQuery.id = this.$route.params.id;
      }

      if (e && typeof e === "number") {
        this.payQuery.page = e;
      } else {
        this.payQuery.page = 1;
      }

      await this.$store
        .dispatch("employee/getEmployeePaySlip", this.payQuery)
        .then((response) => {
          if (response.status_code == 200) {
            this.payslips = response.data.data;
            this.totalPayslip = response.data.total;
            this.formPayslip = response.data.from;
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    downloadDocument(documentFile) {
      if (documentFile.includes("pdf")) {
        window.open(
          documentFile,
          "_blank"
        );
      } else {
        htmlToImage
        .toPng(document.getElementById("print-me"))
        .then(function (dataUrl) {
          var link = document.createElement("a");
          link.download = new Date().getTime() + ".png";
          link.href = dataUrl;
          link.click();

          // stop console.log
          return false;
        });
      }
    },
    PrintMe() {
      const options = {
        importStyle: false,
      };
      printHtmlBlock("#printMe", options);
    },
  },
};
</script>

<style scoped>
.preview {
  border-color: transparent !important;
  background: linear-gradient(315deg, white 100%, white 100%, white 100%);
}

.passport-padding {
  background-color: rgb(236, 231, 231) !important;
  padding: 8px;
}

button.btn.btn-sm.btn-primary.d-block.ms-auto {
  margin-left: auto;
}

.edit-btn {
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);

  border-color: transparent !important;
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
}

.btn-text {
  font-weight: 700;
  font-size: 13px;
  line-height: 21px;
  padding-right: 10px;
  color: #ffffff;
}

.data-text {
  /* 18 bold */
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */
  /* blue main */
  color: #01185e;
  background-color: #efefef;
}

.date-text {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
}
.custom-text {
  padding: 10px;
  border-radius: 5px;
}
.skill {
  padding: 7px;
  border-radius: 5px;
}

.img-border {
  padding: 10px;
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
}

.user-img {
  object-fit: cover;
  object-position: center;
}

.data-text-custom {
  /* font-weight: 700; */
  font-size: 14px;
  line-height: 24px;
}
.date-text {
  padding: 7px;
  border-radius: 5px;
  border: 1px solid #d8d6de;
}

.initialPadding {
  padding: 12px;
}

.download-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.print-icon {
  width: 18px;
  height: 18px;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
