<template>
  <div id="employee-roster">
    <b-card>
        <p class="summery-text">Published Rosters</p>
      <FullCalendar ref="FullCalendar" :options="calendarOptions">

      </FullCalendar>
    </b-card>
  </div>
</template>
<script>
import { getToken } from "@/utils/auth";
import { toast } from "@/utils/toast";
import { VUE_APP_BASE_URL } from "@/utils/var";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import FullCalendar from "@fullcalendar/vue";
import { BButton, BCard, BFormGroup, BFormInput, BFormTimepicker, BModal } from "bootstrap-vue";
export default {
  components: {
    BCard,
    BFormGroup,
    BFormInput,
    BFormTimepicker,
    BButton,
    BModal,
    FullCalendar, // make the <FullCalendar> tag available
  },
  props: {
    filterData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      clicked: "accept",
      duties: [],
      customers: [],
      companies: [],
      shifts: [],
      query: {
        role: "admin",
        company: null,
        customer: null,
        user_id: null,
        customer_id: null,
      },
      roster: {
        company: null,
        duty: null,
        shift: null,
        customer: null,
        customer_id: "",
        user_id: "",
        duty_id: "",
        start_time: "",
        end_time: "",
        date: "",
        employee_id: "",
        shift_id: "",
      },
      nameState: null,
      calendarOptions: {
        plugins: [resourceTimelinePlugin, interactionPlugin, dayGridPlugin],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "fourTeenDays, resourceTimelineThirtyDay",
        },
        initialView: "fourTeenDays",
        aspectRatio: 1.5,
        eventTimeFormat: {
          date: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        },
        views: {
          fourTeenDays: {
            type: 'dayGrid',
            duration: {week: 2},
            rows: 2,
            buttonText: "14 days",
          },
          resourceTimelineThirtyDay: {
            type: 'dayGrid',
            duration: {week: 4},
            rows: 4,
            buttonText: "Month",
          },
        },
        slotLabelFormat: [
          { year: "numeric", month: "short", day: "numeric" },
        ],
        schedulerLicenseKey: "GPL-My-Project-Is-Open-Source",
        resourceAreaWidth: "0.1%",
        eventAreaWidth: "100%",
        slotLabelInterval: { days: 1 },
        eventClick(info) {
          let self = this.el.__vue__;
          self.$parent.roster = info.event.extendedProps;
        },

        //update event
        eventDrop: function (info) {
          alert(
            `Event Drop <> Employee ID: ${info?.event?.id}-${info?.event?.title} <> Date: ${info?.event?.startStr}`
          );
        },
        events: {
          url: `${VUE_APP_BASE_URL}/roster/event`,
          method: "GET",
          extraParams: {
            type: "event",
            Authorization: `Bearer ${getToken()}`,
            status: 1,
            employee_id : this.$route.params.id,
          },
        },
        resources: {
          url: `${VUE_APP_BASE_URL}/roster/resource`,
          method: "GET",
          extraParams: {
            Authorization: `Bearer ${getToken()}`,
            employee_id : this.$route.params.id,
          },
        },
        eventDidMount: function (info) {
          info.el.classList.add("no-event");
          if (info.event?.title == "Add Roster") {
            info.el.style.margin = "5px";
            if (info.event?.extendedProps?.count == 0) {
              info.el.style.backgroundColor = "#f9f9f9";
              info.el.style.padding = "50px 10px";
              let title = document.createElement("span");
              let icon = document.createElement("i");
              icon.style.textAlign = "center";
              icon.style.fontSize = "14px";
              icon.style.color = "#A8ADAF";
              icon.innerHTML = `Unallocated`;
              title.appendChild(icon);
              info.el.prepend(title);
              info.el.querySelector(".fc-event-main").style.display =
                "none !important";
              info.el.classList.add("custom-event");
            }
          } else {
            info.el.style.padding = "16px 2px";
            info.el.style.flexDirection = "column";
            info.el.style.backgroundColor = info.event.backgroundColor;
            info.el.style.color = "#fff";
            let div = document.createElement("div");
            div.style.marginTop = "5px";
            div.style.display = "flex";
            div.style.justifyContent = "space-between";
            div.style.flexDirection = "column";
            div.style.alignItems = "center";
            div.style.width = "100%";

            let title = document.createElement("div");
            title.style.fontSize = "12px";
            title.style.fontWeight = "bold";
            // title.classList.add("duty");
            title.style.marginTop = "5px";
            title.innerHTML = new Date(info.event.startStr).toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'}) + ' - ' + new Date(info.event.endStr).toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'});

            let div1 = document.createElement("div");
            div1.style.fontSize = "14px";
            div1.style.fontWeight = "bold";
            div1.classList.add("duty");
            div1.style.marginTop = "5px";
            div1.innerHTML = info.event.extendedProps.duty
              ? info.event.extendedProps.duty.name
              : "N/A";
              div1.setAttribute('title', 'Assigned Duty');

            let client = document.createElement("div");
            client.classList.add("duty");
            client.style.fontSize = "14px";
            client.style.marginTop = "5px";
            client.style.fontWeight = "bold";
            client.innerHTML = info.event.extendedProps.client_company
              ? info.event.extendedProps.client_company
              : "N/A";
            client.setAttribute('title', 'Client Company');

            let location = document.createElement("div");
            location.classList.add("duty");
            // add location name and sub location name if sub location is available else add location name only to the event
            location.innerHTML = info.event.extendedProps.sub_location
              ? `${info.event.extendedProps.sub_location.name}, ${info.event.extendedProps.location.name}`
              : info.event.extendedProps.location ? info.event.extendedProps.location.name : '';

            location.setAttribute("title", "Location");

            if(info.event.extendedProps.status === 1 && info.event.extendedProps.e_status === 2){
              let accept = document.createElement("div");
              accept.innerHTML = `<button type="button" class="btn btn-sm btn-success rounded text-light btn-accept custom">Accepted</button> `;

              div.append(accept);
            }

            if(info.event.extendedProps.status === 1 && info.event.extendedProps.e_status === 3){
              let accept = document.createElement("div");
              accept.innerHTML = `<button type="button" class="btn btn-sm btn-danger rounded text-light btn-accept custom">Declined</button> `;

              div.append(accept);
            }
            
            info.el.append(title, client, div1, location, div);
          }
        },
        
      },
    };
  },
  methods: {
    handleOkDecline(){
      let data = {
        id: [this.roster.roster_id],
        status: 3,
      };
      this.handleUdate(data);
    },
    handleOkAccept(){
      let data = {
        id: [this.roster.roster_id],
        status: 2,
      };
      this.handleUdate(data);
    },
    handleUdate(data) {
      if(data.id.length > 0){
        this.$store.dispatch("roster/acceptDecline", data).then((res) => {
          if (res.status_code == 200) {
            toast(
              "Success",
              "CheckIcon",
              "success",
              "Roster published successfully"
            );
            this.resetModal();
            this.$refs.FullCalendar.getApi().refetchEvents();
            this.$refs.publishModal.hide();
          }
        }).catch((err) => {
          console.log(err);
          toast(
            "Error",
            "AlertTriangleIcon",
            "danger",
            "Something went wrong"
          );
        })
      }
    },
    resetModal() {
      this.roster = {
        id: "",
        date: "",
        start_time: "",
        end_time: "",
        employee_id: "",
        customer_id: "",
        status: "",
        shift: "",
        duty: "",
      };
    },
  }
};
</script>
<style scoped>
.fc-license-message {
  display: none;
}

.timeline-event {
  background-color: #f00;
  color: #000;
  border: 1px solid #f00;
  border-radius: 3px;
  padding: 2px 5px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
}
.fc-datagrid-cell-frame:last-child {
  border: 1px solid #bebebe;
}
.fc-timeline-lane-frame {
  border: 1px solid #bebebe;
}

.labelLane {
  align-items: center !important;
  border-radius: 0;
  display: flex !important;
  /*font-size: var(--fc-small-font-size);*/
  margin-bottom: 1px !important;
  padding: 2px 1px !important;
  position: relative !important;
  font-weight: bold;
}

.timeline-event {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.fc-timeline-events .fc-event-main i,
.fc-timeline-events .fc-event-main {
  color: #a8adaf !important;
  text-align: center;
  display: none;
}

.fc-timeline-events {
  /* height: 50% !important; */
  display: flex;
  align-items: center;
  flex-direction: column !important;
}
.fc .fc-scrollgrid-liquid {
  /* height: 50% !important; */
}
.summery-text {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: #7190ef;
}

</style>

