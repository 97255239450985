<template>
  <b-row id="employee-image-show" v-if="employee.id">
    <b-col cols="12" class="rounded-lg">
     
      <employee-preview-basic-info :availability="availability" :employee="employee" />
    </b-col>
    <b-col cols="12" class="rounded-lg">

    </b-col>
  </b-row>
</template>
<script>
import { BCard, BCol, BRow } from "bootstrap-vue";
import EmployeePreviewBasicInfo from "./components/employee-preview-basic-info.vue";

export default {
  data() {
    return {
      employee: {},
      headerItems: [],
      workHistory: [],
      availability: [],
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    EmployeePreviewBasicInfo,
  },
  created() {
    const Id = this.$route.params.id;
    this.getEmployee(Id);
  },
  methods: {
    getEmployee(id) {
      this.$store.dispatch("employee/getEmployee", id).then((res) => {
        this.employee = res.data;
        this.availability = res.data.availability;
        if (!this.employee.employee.keen) {
          this.employee.employee.keen = {
            address: "",
            relation: "",
            phone: "",
            name: "",
            email: "",
          };
        }
        if (!this.employee.employee.visa) {
          this.employee.employee.visa = {
            id: this.employee.employee.id,
            employee_id: this.employee.employee.id,
            resident_type: "",
            expiry_date: "",
            visa_grant_number: "",
            visa_type: "",
            sub_class: "",
            type: "",
          };
        }
        if (!this.employee.employee.passport) {
          this.employee.employee.passport = {
            passport_issue_country: "",
            passport_file: "",
            passport_number: "",
            passport_validation: "",
          };
        }
        if (!this.employee.employee.driving) {
          this.employee.employee.driving = {
            issuing_state: "",
            licence_file: "",
            licence_number: "",
            license_validation: "",
          };
        }
        if (!this.employee.employee.bank_info) {
          this.employee.employee.bank_info = {
            account_type: "",
            address: "",
            bank_name: "",
            personal_account_number: "",
          };
        }
        if (!this.employee.employee.super) {
          this.employee.employee.super = {
            address: "",
            email: "",
            first_name: "",
            last_name: "",
            phone: "",
          };
        }
        if (this.employee.employee.certificate && this.employee.employee.certificate.length < 1) {
          this.employee.employee.certificate.push({
            certificate_file: "",
            certificate_name: "",
            certificate_validation: "",
            certificate_issuing_authority: "",
            certificate_issuing_date: "",
            certificate_number: "",
          });
        }
      });
    },
  },
  watch: {
    employee: {
      handler: function (val, oldVal) {
        this.employee = val;
      },
      deep: true,
    },
  },
};
</script>
<style scoped></style>
